import Base, { ShippingState } from 'core/pages/Shipping/ShippingReading'
import { DecodedItem } from 'stylewhere/api'
import { ShippingExtensions } from 'stylewhere/extensions'
import { OperationReadingProps, OperationReadingProvider } from 'stylewhere/shared'
import { __, T } from 'stylewhere/shared/i18n'
import { filterIgnoredAndErrorItems, showToastError } from 'stylewhere/shared/utils'

export default class ShippingReading extends Base<OperationReadingProps<ShippingState>, ShippingState> {
  alertOnNfcTagRead =
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.operation.attributes['alertOnNfcTagRead'] && this.operation.attributes['alertOnNfcTagRead'] === 'true'

  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    const { items, parcel, formData, removeModeEnabled } = this.state
    if (removeModeEnabled && this.isRemoveModeByRfidRead) {
      const itemsToRemove = Object.values(itemMapFromReader)
      itemsToRemove.forEach((item) => this.removeItem(item))
    } else {
      if (this.alertOnNfcTagRead && Object.keys(itemMapFromReader).some((tagCode) => tagCode.startsWith('E0'))) {
        showToastError(__(T.custom.nfcDetectedPleaseUseProductControl), __(T.error.error), true)
        if (this.antennaRef && this.antennaRef.current) {
          await this.antennaRef.current.stopReader()
        }
        return
      }
      const processedItems: DecodedItem[] = await OperationReadingProvider.processDecodedItems(
        this.operation,
        itemMapFromReader,
        items,
        formData,
        ShippingExtensions
      )
      if (this.isBatchReadingMode) {
        if (!this.state.parcel) return
        try {
          await this.updateParcel(
            'INSERT',
            processedItems
              .filter((itm) =>
                Object.values(itemMapFromReader)
                  .map((readerItem) => readerItem.item?.id)
                  .includes(itm.item?.id)
              )
              .filter(filterIgnoredAndErrorItems)
              .flatMap(({ item }) => (item?.id ? item.id : [])),
            this.state.parcel?.code
          )
        } catch (error) {
          showToastError(error, __(T.error.error), this.isModal)
        }
      }
      this.setState({ items: processedItems }, () => {
        this.onItemsDecodedCallback()

        if (parcel) {
          if (this.parcelIsSuitableForConfirmation()) {
            this.onParcelConfirmIsSuitable()
          }
        }
      })
    }
  }

  render(): JSX.Element {
    return super.render()
  }
}
