import { EncodingExtensions as Base } from 'core/extensions/EncodingExtensions'
import {
  EncodingCounters,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  IdentifierType,
  Products,
  TmrTag,
} from 'stylewhere/api'
import { MutualAuth } from 'stylewhere/api/MutualAuth'
import { AppStore, FormSchemaData, RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  decToHex,
  getEncodingBlockedErrors,
  getInitialType,
  putSpacesEveryByte,
  reverseString,
} from 'stylewhere/shared/utils'

export class EncodingExtensions extends Base {
  static async getItemConfiguration(
    operation: EncodingOperationConfig,
    data: FormSchemaData,
    operationAttributes?: any
  ) {
    try {
      const initialType = getInitialType(operation)
      const encodingValidation: EncodingValidationRequest = {
        identifiers: [],
        operationId: operation.id,
        placeId: AppStore.defaultWorkstation!.placeId,
        workstationId: AppStore.defaultWorkstation!.id,
        operationAttributes: operationAttributes || {},
      }
      if (initialType === 'product') {
        encodingValidation.startEntityId = await this.getProductId(data)
      } else if (initialType === 'order' || initialType === 'orderRow') {
        encodingValidation.startEntityId = data.productionOrderRow.id
      } else if (initialType === 'wam') {
        encodingValidation.startCode = data.wam
      }
      console.log('getItemConfiguration', encodingValidation)
      const res = await Encodings.validate(encodingValidation)
      if (res) {
        let configurationDetails
        if (res.configurationDetails) configurationDetails = res.configurationDetails
        else if (res.configuration && res.configuration.details) configurationDetails = res.configuration.details
        if (!configurationDetails) configurationDetails = []

        if (configurationDetails.length === 0) {
          const missingOptionError = res.errors.find((e) => e.errorCode === 'Missing Operation Option')
          if (missingOptionError) {
            throw new Error(__(T.error.missing_operation_option, { error: missingOptionError.ref }))
          }
          throw new Error(__(T.error.no_item_configuration))
        }

        const blockedError = res.errors.find((e) => getEncodingBlockedErrors().includes(e.errorCode))
        if (blockedError) {
          throw new Error(__(T.error[blockedError.errorCode]))
        }

        let counters: EncodingCounters | undefined
        if ((initialType === 'order' || initialType === 'orderRow') && !!encodingValidation.startEntityId) {
          counters = (await Encodings.getProductionOrderRowCounter(encodingValidation.startEntityId)).data
        }

        const expectOnlyMandatoryIdentifiers = this.isExpectOnlyMandatoryIdentifiers(operation)
        configurationDetails.forEach((configDetail) => {
          if (!expectOnlyMandatoryIdentifiers || (expectOnlyMandatoryIdentifiers && !configDetail.optional)) {
            encodingValidation.identifiers.push({ ...configDetail })
          }
        })
        return { encodingValidationResponse: res, encodingValidation, counters }
      } else {
        throw new Error(__(T.error.no_item_configuration))
      }
    } catch (error) {
      throw new Error((error as any)?.message ?? __(T.error.no_item_configuration))
    }
  }

  static async getProductId(data) {
    const products = await Products.searchCodes([data.product.code])
    if (!products || !products.content || products.content.length !== 1) {
      throw new Error(__(T.error.no_item_configuration))
    }
    return products.content[0].id
  }

  // static resetIdentifiersEncodingValition(
  //   operation: EncodingOperationConfig,
  //   encodingValidationResponse?: EncodingValidationResponse,
  //   encodingValidation?: EncodingValidationRequest
  // ) {
  //   // keep serial
  //   const serial = encodingValidation?.identifiers.find((idf) => idf.identifierType === 'SIMPLE_ITEM_IDENTIFIER')
  //   if (serial) serial._status = 'SKIPPED'
  //   return [
  //     ...(encodingValidation?.identifiers ?? [])
  //       .filter((idf) => idf.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
  //       .concat(serial ? [serial] : []),
  //   ]
  // }

  static async onTagRead(
    encodingValidation: EncodingValidationRequest,
    _tag: TmrTag,
    operationConfig: EncodingOperationConfig
  ) {
    let identifierType = 'UHF_TAG'
    if (!!_tag.uid) identifierType = 'NFC_TAG'
    if (!!_tag.barcode) identifierType = 'SIMPLE_ITEM_IDENTIFIER'

    if (
      _tag.uid &&
      operationConfig.attributes['nfc-requires-mutual-authentication'] &&
      operationConfig.attributes['nfc-requires-mutual-authentication'] === 'true'
    ) {
      try {
        await Promise.race([
          new Promise((_, reject) => {
            setTimeout(() => {
              reject(new Error(__(T.error.authentication_timeout)))
            }, 10000) // if the authentication takes more than 10 seconds, reject
          }),
          (async () => {
            const result = await this.nfcMutualAuthentication(_tag)
            if (result === false) {
              // auth failed
              console.log('auth result: false', _tag.uid)
              const newEncodingValidation = { ...encodingValidation }
              newEncodingValidation.identifiers.forEach((element) => {
                if (element.identifierType === 'NFC_TAG') {
                  element._status = 'ERROR'
                  element._error = __(T.error.tag_error_or_not_authentic)
                }
              })
              encodingValidation = newEncodingValidation
            } else if (result === undefined) {
              // auth fail
              console.log('auth result: undefined', _tag.uid)
              const newEncodingValidation = { ...encodingValidation }
              newEncodingValidation.identifiers = newEncodingValidation.identifiers.filter(
                (element) => element.code !== _tag.uid
              )
              encodingValidation = newEncodingValidation
            } else {
              // auth success
              console.log('auth result: success', result.finalUid)
              // sets the real tag info
              _tag.uid = result.finalUid
              // _tag.cxid = result.cxid
            }
          })(),
        ])
      } catch (error: any) {
        console.log('extension catch', error)
        if (error.message.includes('Collision')) {
          await RfidReader.stopWsCustomCommands()
          const newEncodingValidation = { ...encodingValidation }
          if (!newEncodingValidation.identifiers.some((id) => id.identifierType === 'NFC_TAG')) {
            newEncodingValidation.identifiers.push({
              code: _tag.uid,
              identifierType: 'NFC_TAG',
            })
          }
          newEncodingValidation.identifiers.forEach((element) => {
            if (element.identifierType === 'NFC_TAG') {
              element._status = 'NFC_COLLISION'
              element._error = __(T.custom.nfcCollision)
            }
          })
          encodingValidation = newEncodingValidation
          return
        }

        const newEncodingValidation = { ...encodingValidation }
        newEncodingValidation.identifiers.forEach((element) => {
          if (element.identifierType === 'NFC_TAG') {
            element._status = 'ERROR'
            element._error = error && error.message ? error.message : error
          }
        })
        encodingValidation = newEncodingValidation
        return
      }
    }

    const tag = {
      code: _tag.epc ?? _tag.uid ?? _tag.barcode,
      identifierType,
    } as { code: string; identifierType: IdentifierType }

    const index = encodingValidation.identifiers.findIndex(
      (identifier) =>
        (!identifier.code && identifier.identifierType === tag.identifierType) ||
        (identifier.code === tag.code && identifier.identifierType === tag.identifierType)
    )

    if (index >= 0) {
      encodingValidation.identifiers[index].code = tag.code
      encodingValidation.identifiers[index]._status = 'PROCESSING'
      encodingValidation.identifiers[index]._error = undefined
      encodingValidation.identifiers[index]._read = true
    } else {
      encodingValidation.identifiers.push({
        code: tag.code,
        identifierType: tag.identifierType,
        _status: 'PROCESSING',
      })
    }
  }

  static nfcMutualAuthentication = async (tag: TmrTag) => {
    const tagId = tag.uid
    if (!tagId) return false

    let collisionCount = 0
    let collision = true

    const dateInMilliseconds = Date.now()
    const dateInHex = decToHex(dateInMilliseconds)
    const challenge = (BigInt(`0x${dateInHex}`) << BigInt(4)).toString(16).toUpperCase()
    // console.log('CHALLENGE', challenge)

    // await RfidReader.stopWsCustomCommands() // ensure antenna is free
    await RfidReader.startWsCustomCommands('ISO_15_AUTH')

    while (collisionCount < 5 && collision) {
      const collisionCheck = JSON.parse(
        await RfidReader.sendWsCustomCommandForResponse(
          {
            command: putSpacesEveryByte('260100'),
            parameters: {
              expectedResponseLength: 10,
            },
          },
          false
        )
      )
      if (collisionCheck.errorDescription && collisionCheck.errorDescription.includes('collision')) {
        collisionCount++
        console.log('Collision check', collisionCount)
      } else {
        collision = false
      }
    }

    if (collision) {
      console.error('NFC Collision detected')
      throw new Error('NFC Collision detected')
    }

    const step1Res = await RfidReader.sendWsCustomCommandForResponse({
      command: putSpacesEveryByte('02D816038000' + challenge),
      parameters: {
        expectedResponseLength: 8,
      },
    })

    const step1ServerRes = await MutualAuth.challenge(step1Res.result, challenge)

    if (!step1ServerRes) return false
    // console.log('STEP 1', step1ServerRes)

    const connectionId = step1ServerRes?.data?.connectionId ?? ''
    const apdu = step1ServerRes?.data?.APDU ?? ''

    if (!connectionId || !apdu) {
      throw new Error('No connection id or apdu from the server')
    }
    // console.log('data.APDU', apdu)

    const step2Res = await RfidReader.sendWsCustomCommandForResponse({
      command: putSpacesEveryByte('02D816039000' + apdu),
      parameters: {
        expectedResponseLength: 9,
      },
    })

    if (step2Res.result === '010F') return false

    await MutualAuth.validate(step2Res.result, connectionId) // if doesn't throw, it's valid

    // const getCxidCommand = '2023' + tagId + '0404'
    // const getCxidCommand = putSpacesEveryByte('02230404')
    // const cxidRes = await RfidReader.sendWsCustomCommandForResponse({
    //   command: getCxidCommand,
    //   parameters: {
    //     expectedResponseLength: 21,
    //   },
    // })
    // // console.log('CXID', cxidRes.result)

    // if (!cxidRes.result || cxidRes.result === '000000000000000000000000000000000000000000') return false
    // const finalCxid = splitAndConvertASCII(cxidRes.result)

    const getFinalUidCommand = putSpacesEveryByte('022B')

    const finalUidRes = await RfidReader.sendWsCustomCommandForResponse({
      command: getFinalUidCommand,
      parameters: {
        expectedResponseLength: 15,
      },
    })

    await RfidReader.stopWsCustomCommands()
    const parsedFinalUid = reverseString(finalUidRes.result.substring(2, 18)).toUpperCase()
    // console.log('FINAL UID', parsedFinalUid)

    return {
      // cxid: finalCxid,
      finalUid: parsedFinalUid,
      challengeString: dateInMilliseconds.toString(),
      challengeInHex: challenge,
    }
  }
}
