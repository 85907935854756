import styled from '@emotion/styled'
import { Box, TableStructure, Modal, Image, Icons, Button, GroupedItemList } from 'stylewhere/components'
import { DecodedItem, IdentifierType, ProductInfoField, Products, TmrProduct } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig, AppStore } from 'stylewhere/shared'
import { Component } from 'react'
import _ from 'lodash'

interface Props {
  isOpen: boolean
  product: TmrProduct
  decodedItems: DecodedItem[]
  operation?: OperationConfig
  onClose: () => void
  onRemoveItem?: (item: DecodedItem) => void
  removeModeEnabled?: boolean
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

const ProductDetailsRow: React.FC<{ label: string; value?: string; last?: boolean }> = ({ label, value, last }) => {
  return (
    <Box
      flex
      row
      justify="space-between"
      style={{ borderBottom: last ? undefined : '1px solid #e0e0e0', lineHeight: '33px' }}
    >
      <Box style={{ fontSize: 14, marginRight: 5 }}>{label}</Box>
      <Box style={{ fontWeight: 700, wordWrap: 'break-word', flex: 1, textAlign: 'end' }}>{value ?? '--'}</Box>
    </Box>
  )
}

export class ProductItemsModal extends Component<Props> {
  structure: TableStructure<DecodedItem>[] = [
    {
      key: 'identifiers',
      label: __(T.misc.identifiers),
      customRender: ({ item }) =>
        item?.identifiers.map((identifier) => <div key={identifier.id}>{identifier.code}</div>),
      width: 300,
    },
    {
      key: 'zone',
      label: __(T.misc.zone),
      customValue: ({ item }) =>
        `${item?.zone?.place.description ?? item?.zone?.place.code ?? '--'} - ${
          item?.zone?.description ?? item?.zone?.code ?? '--'
        }`,
      flex: 1,
    },
  ]

  getProductCode = (product) => product.code

  productFields: ProductInfoField[] = [
    { path: 'color.value', label: __(T.misc.color) },
    { path: 'material.value', label: __(T.misc.material) },
    { path: 'size.value', label: __(T.misc.size) },
    { path: 'style.value', label: __(T.misc.style) },
  ]

  getProductFields = () => {
    const { overrideProductInfoFields } = this.props
    if (overrideProductInfoFields) {
      return overrideProductInfoFields
    }
    return this.productFields
  }

  removeAllItems() {
    const { onRemoveItem, decodedItems } = this.props
    if (onRemoveItem) {
      decodedItems.forEach(async (item) => {
        onRemoveItem(item)
      })
    }
  }

  render() {
    const {
      isOpen,
      onClose,
      product,
      decodedItems,
      onRemoveItem,
      removeModeEnabled,
      operation,
      hideIdentifiersByType,
      hideIdentifiersZones,
      overrideProductInfoFields,
    } = this.props
    if (onRemoveItem && removeModeEnabled) {
      this.structure.push({
        key: 'delete',
        customRender: (item) => (
          <Button onClick={() => onRemoveItem(item)} variant="secondary" size="small" circle>
            <Icons.Delete width={24} height={24} />
          </Button>
        ),
        width: 78,
      })
    }

    return (
      <Modal visible={isOpen ?? true} onClose={onClose} size="6xl" showCloseButton padding="12">
        <Box style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Title>{__(T.misc.product_items)}</Title>
          {removeModeEnabled && decodedItems.length > 0 && operation?.removeMode === 'sku' && (
            <Button style={{}} onClick={() => this.removeAllItems()} variant="secondary">
              <Box flex row center style={{ gap: 8 }}>
                <p>{__(T.misc.remove_product_readings)}</p>
                <Icons.Delete />
              </Box>
            </Button>
          )}
        </Box>
        <Box flex row style={{ marginTop: 25 }}>
          <Box pr={25} style={{ maxWidth: '30%', minWidth: '30%' }}>
            {AppStore.getShowProductImage() && (
              <Image src={Products.getImageUrl(product)} width={200} height={200} style={{ marginBottom: 16 }} />
            )}
            <ProductTitle>{this.getProductCode(product)}</ProductTitle>
            <div>{product.description}</div>
            <Box mt={16}>
              {this.getProductFields().map((field, idx, all) => {
                return (
                  <ProductDetailsRow
                    key={field.path}
                    label={field.label}
                    value={_.get(product, field.path ?? '', 'n/a')}
                    last={idx === all.length - 1}
                  />
                )
              })}
            </Box>
          </Box>
          <Box flex style={{ minHeight: 560 }}>
            <GroupedItemList
              hideProductInfo
              operation={operation}
              rows={decodedItems}
              disabledCounter
              onRemoveItem={onRemoveItem}
              hideIdentifiersByType={hideIdentifiersByType}
              hideIdentifiersZones={hideIdentifiersZones}
              overrideProductInfoFields={overrideProductInfoFields}
            />
          </Box>
        </Box>
      </Modal>
    )
  }
}

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 25px;
`

const ProductTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
`
