import { Component } from 'react'
import { RfidReader } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/i18n'
import styled from '@emotion/styled'
import { Box, Icons, Spacer } from 'stylewhere/components'
import { DecodedItem, TmrTag, DecodeRequest } from 'stylewhere/api'
import { animations } from 'stylewhere/assets'
import Lottie from 'react-lottie'

interface Props {
  onStart?: () => void
  onItemDecoded?: (items: { [epc: string]: DecodedItem }, tags?: TmrTag[]) => void
  onTagReadCallback?: (tag: TmrTag) => Promise<void>
  setOnDecodedItemCallback?: () => void
  onClear?: () => void
  onError?: () => void
  decodeFunction?: (epcs: string[]) => Promise<any>
  decodeRequest?: DecodeRequest
  icon?: string
  content?: JSX.Element
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  leftBottom?: boolean
  hideClear?: boolean
  showPendingTags?: boolean
  disabled?: boolean
  iconOnly?: boolean
  initReaderTags?: string[]
  overrideStartLabel?: string
}

interface State {
  reading: boolean
  starting: boolean
  pendingTags: number
  loading: boolean
  clearing: boolean
}

export class AntennaButton extends Component<Props, State> {
  static defaultProps = {
    leftBottom: false,
  }

  state: State = {
    reading: false,
    starting: false,
    pendingTags: 0,
    loading: false,
    clearing: false,
  }

  constructor(props: Props) {
    super(props)
    const { onItemDecoded, decodeFunction, decodeRequest, onTagReadCallback, setOnDecodedItemCallback } = this.props
    RfidReader.initialize()
    RfidReader.onStartCallbackAntennaButton = this.onStartCallback
    RfidReader.onStopCallbackAntennaButton = this.onStopCallback
    if (onItemDecoded) {
      RfidReader.onDecodedItemCallback = onItemDecoded
    }
    if (decodeFunction) {
      RfidReader.decodeFunction = decodeFunction
    }
    if (decodeRequest) {
      RfidReader.decodeRequest = decodeRequest
    }
    if (onTagReadCallback) {
      RfidReader.onTagReadCallback = onTagReadCallback
    }
    if (setOnDecodedItemCallback) {
      setOnDecodedItemCallback()
    }
    RfidReader.onPendingTagsChangeAntennaButton = (pendingTags: number) => this.setState({ pendingTags })
  }

  shouldComponentUpdate = (nextProps) => {
    // aggiunta gestione per rimuovere in automatico dei tag UHF dal reader se passati in props al componente
    // gestione fatta in questo componente per evitare che initialize fatto al componentDidMount resetti i tags dopo che siano stati impostati dalla schermata padre
    if (
      this.props.initReaderTags &&
      nextProps.initReaderTags &&
      JSON.stringify(nextProps.initReaderTags) !== JSON.stringify(this.props.initReaderTags)
    ) {
      RfidReader.initUhfTags(nextProps.initReaderTags)
    }
    return true
  }

  componentWillUnmount() {
    this.stop()
    RfidReader.clear()
  }

  async start(): Promise<boolean> {
    if (RfidReader.isReading()) return false
    const { onError, onStart } = this.props
    this.setState({ starting: true })
    onStart?.()
    const result = await RfidReader.start(undefined, undefined, onError)
    this.setState({ starting: false })
    return result
  }

  async stop(): Promise<boolean> {
    return RfidReader.isReading() ? RfidReader.stop() : false
  }

  onStartCallback = () => this.setState({ reading: true })

  onStopCallback = () => this.setState({ reading: false })

  onClickAntennaButton = async () => {
    const { loading } = this.state
    if (loading || this.props.disabled) return
    this.setState({ loading: true })
    try {
      RfidReader.isReading() ? await this.stop() : await this.start()
    } catch (error) {
      //ignore
    }
    this.setState({ loading: false })
  }

  onClear = async () => {
    const { clearing } = this.state
    const { onClear } = this.props
    if (clearing) return
    this.setState({ clearing: true })
    try {
      RfidReader.clear()
      onClear && (await onClear())
    } catch (error) {
      //ignore
    }
    this.setState({ clearing: false })
  }

  renderPlayIcon = () => {
    const { starting, loading, reading } = this.state
    const style = {
      width: 28,
      height: 28,
    }
    if (reading) {
      return <Icons.Pause style={style} />
    }
    if (starting || loading) {
      return <Icons.Loader style={style} />
    }

    return <Icons.Play style={{ ...style, marginLeft: this.props.iconOnly ? 10 : 0 }} />
  }

  renderClearIcon = () => {
    const { clearing } = this.state
    const style = {
      width: 28,
      height: 28,
    }
    if (clearing) {
      return <Icons.Loader style={style} />
    }

    return <Icons.Retry style={style} />
  }

  startReader = async () => {
    if (!RfidReader.isReading()) {
      await this.onClickAntennaButton()
    }
  }

  stopReader = async () => {
    if (RfidReader.isReading()) {
      await this.onClickAntennaButton()
    }
  }

  render() {
    const {
      style,
      buttonStyle,
      onClear,
      hideClear,
      showPendingTags = true,
      iconOnly,
      disabled,
      overrideStartLabel,
    } = this.props
    const { reading, pendingTags } = this.state
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animations.Onde,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    return (
      <>
        <Container row vcenter style={style}>
          <ButtonControl
            style={{ ...buttonStyle }}
            row
            center
            reading={reading}
            iconOnly={iconOnly}
            onClick={this.onClickAntennaButton}
            disabled={disabled}
          >
            {this.renderPlayIcon()}
            {!reading && !iconOnly && (
              <Box flex center>
                {overrideStartLabel ?? __(T.misc.start)}
              </Box>
            )}
          </ButtonControl>
          {reading && !iconOnly && (
            <div onClick={this.onClickAntennaButton}>
              <Lottie isClickToPauseDisabled style={{ minWidth: 200, height: 70 }} options={defaultOptions} />
            </div>
          )}
          {!hideClear && onClear && !reading && (
            <>
              <Spacer style={{ height: 5, width: 5, minHeight: 5, minWidth: 5 }} />
              <ButtonClear style={buttonStyle} row center onClick={this.onClear || undefined}>
                {this.renderClearIcon()}
                {!iconOnly && (
                  <Box flex center>
                    {__(T.misc.clear)}
                  </Box>
                )}
              </ButtonClear>
            </>
          )}
        </Container>
        {showPendingTags && pendingTags > 0 && (
          <PendingContainer row>
            {__(T.misc.pending_tags)} <div style={{ fontWeight: 900 }}>{pendingTags}</div>
          </PendingContainer>
        )}
      </>
    )
  }
}

const PendingContainer = styled(Box)`
  margin-top: 15px;
  background: #e4e4e4;
  border-radius: 50px;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 18px;
  justify-content: space-between;
`

const Container = styled(Box)`
  background-color: white;
  box-shadow: 0 1px 5px 0 #00000014;
  border-radius: 50px;
  padding: 7px;
`

const ButtonControl = styled(Box)<{ reading?: boolean; iconOnly?: boolean; disabled?: boolean }>`
  flex: ${(props) => (props.reading ? 0 : 1)};
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 -1px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 50px;
  height: 70px;
  min-width: 70px;
  width: ${({ iconOnly }) => (iconOnly ? '70px' : 'unset')};
  cursor: pointer;
  font-weight: 900;
  padding: ${({ iconOnly }) => (iconOnly ? '0px' : '0 20px')};
  font-size: 22px;
  position: relative;
  background-color: ${({ reading }) => (reading ? '#525252' : '#ceff00')};
  margin-right: ${({ reading, iconOnly }) => (reading && !iconOnly ? '5px' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.5 !important' : '1')};
`

const ButtonClear = styled(ButtonControl)`
  background-color: #e9e9e9;
`
