import { Component } from 'react'
import { ConfigurationDetail, EncodingValidationRequest, IdentifierType } from 'stylewhere/api'
import { Box, IdentifierBox } from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  identifiers?: ConfigurationDetail[]
  clearReads: any
  encodingValidation?: EncodingValidationRequest
  onTagRead?: any
  associationStatus: 'TO_BE_READ' | 'CONFIRMED' | 'ERROR' | 'PROCESSING' | 'IGNORED' | 'SKIPPED' | 'WRITING' | 'READY'
  disabledRetry?: boolean
  layout?: any
  marginBottom?: number
  lineOfMax3Identifiers?: boolean
  fullWidthAssociationBox?: boolean
  labelsByType?: Partial<Record<IdentifierType, string>>
}

export class EncodingIdentifers extends Component<Props> {
  renderIdentifierBox = (_identifier: ConfigurationDetail, index: any) => {
    const {
      onTagRead,
      layout,
      marginBottom,
      identifiers,
      lineOfMax3Identifiers,
      fullWidthAssociationBox,
      labelsByType,
    } = this.props
    let mb = marginBottom || 16
    const identifiersLength =
      identifiers?.filter(
        (idt) =>
          idt.identifierType !== 'SIMPLE_ITEM_IDENTIFIER' ||
          (idt.identifierType === 'SIMPLE_ITEM_IDENTIFIER' &&
            (!idt._status || idt._status === 'CONFIRMED' || idt._status === 'PROCESSING' || idt._status === 'ERROR'))
      ).length || 0
    if (!fullWidthAssociationBox && identifiersLength % 2 === 1 && index === identifiersLength - 1) mb = 0
    return (
      <IdentifierBox
        widthPerc={
          lineOfMax3Identifiers
            ? (identifiers?.length || 0) <= 3
              ? 100 / (identifiers?.length || 1)
              : 33
            : (identifiers?.length || 0) < 3
            ? 100
            : 49.5
        }
        inLine={lineOfMax3Identifiers}
        identifier={_identifier}
        key={index}
        onInputSubmit={onTagRead ? (barcode) => onTagRead({ barcode } as any) : undefined}
        layout={layout}
        marginBottom={mb}
        labelsByType={labelsByType}
      />
    )
  }

  render() {
    const {
      identifiers,
      clearReads,
      associationStatus,
      disabledRetry,
      layout,
      lineOfMax3Identifiers,
      fullWidthAssociationBox,
    } = this.props
    const identifiersLength =
      identifiers?.filter(
        (idt) =>
          idt.identifierType !== 'SIMPLE_ITEM_IDENTIFIER' ||
          (idt.identifierType === 'SIMPLE_ITEM_IDENTIFIER' && (idt._status === 'CONFIRMED' || idt._status === 'ERROR'))
      ).length || 0
    const fullWidthAssociation = fullWidthAssociationBox || identifiersLength < 3 || identifiersLength % 2 === 0
    return identifiers ? (
      <Box
        flex={lineOfMax3Identifiers ? 0 : 1}
        row
        justify={!lineOfMax3Identifiers ? 'space-between' : undefined}
        style={{ flexWrap: 'wrap', padding: 2, columnGap: 6 }}
      >
        {EncodingExtensions.sortIdentifiers(identifiers).map(this.renderIdentifierBox)}
        <IdentifierBox
          widthPerc={fullWidthAssociation ? 100 : 49.5}
          marginBottom={0}
          identifier={{
            identifierType: 'ASSOCIATION',
            _status: associationStatus,
          }}
          isAssociation
          inLine
          identifiersCount={identifiers.length}
          layout={layout}
          buttons={
            associationStatus === 'ERROR' && !disabledRetry ? [{ text: __(T.misc.retry), onClick: clearReads }] : []
          }
        />
      </Box>
    ) : null
  }
}
