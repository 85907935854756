import styled from '@emotion/styled'
import { Box, Modal, GroupedItemList } from 'stylewhere/components'
import { DecodedItem, IdentifierType, ProductInfoField } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { OperationConfig } from 'stylewhere/shared'

interface Props {
  title?: string
  isOpen: boolean
  decodedItems: DecodedItem[]
  operation?: OperationConfig
  onClose: () => void
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

export const DecodedItemsModal: React.FC<Props> = ({
  title = __(T.misc.product_items),
  isOpen,
  onClose,
  decodedItems,
  operation,
  hideIdentifiersByType,
  hideIdentifiersZones,
  overrideProductInfoFields,
}) => {
  if (!isOpen) return null

  return (
    <Modal visible={isOpen} onClose={onClose} size="3xl" isCentered>
      <Box height="70vh">
        <Title>
          {title} ({decodedItems?.length ?? 0})
        </Title>
        <Box flex>
          <GroupedItemList
            operation={operation}
            rows={decodedItems}
            hideIdentifiersByType={hideIdentifiersByType}
            hideIdentifiersZones={hideIdentifiersZones}
            overrideProductInfoFields={overrideProductInfoFields}
          />
        </Box>
      </Box>
    </Modal>
  )
}

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 25px;
`
