import { PureComponent } from 'react'
import styled from '@emotion/styled'
import {
  Image,
  Box,
  ProductItemsModal,
  GroupedListRow,
  GroupedProductRowInfo,
  Icons,
  Button,
} from 'stylewhere/components'
import { DecodedItem, IdentifierType, ProductInfoField, Products, TmrProduct, TmrStatus } from 'stylewhere/api'
import { isNumber, hasChecklist } from 'stylewhere/utils'
import { T, __, __UP } from 'stylewhere/i18n'
import { OperationConfig, ShippingOperationConfig, AppStore } from 'stylewhere/shared'

interface Props {
  operation: OperationConfig | ShippingOperationConfig
  product: TmrProduct
  detected?: number
  expected?: number
  unexpected?: number
  hideCounter?: boolean
  noShadow?: boolean
  flex?: boolean
  status?: TmrStatus
  decodedItems: DecodedItem[]
  onRemoveItem?: (item: DecodedItem) => void
  onActionItem?: (item: DecodedItem) => void
  removeModeEnabled?: boolean
  hideIdentifiersByType?: IdentifierType[]
  hideIdentifiersZones?: boolean
  overrideProductInfoFields?: ProductInfoField[]
}

interface State {
  modalVisible: boolean
}

export class GroupedProductRow extends PureComponent<Props, State> {
  static Skeleton = styled(Box)``

  state: State = {
    modalVisible: false,
  }

  managerModal = () => {
    const { modalVisible } = this.state
    this.setState({ modalVisible: !modalVisible })
  }

  render() {
    const {
      product,
      detected,
      operation,
      expected,
      unexpected,
      decodedItems,
      hideCounter,
      noShadow,
      flex,
      status,
      onRemoveItem,
      removeModeEnabled,
      hideIdentifiersByType,
      hideIdentifiersZones,
      overrideProductInfoFields,
    } = this.props
    const read = detected ?? 0 // + (unexpected ?? 0)
    const withChecklist = hasChecklist(operation)
    const counterValue = expected ? `${read}/${expected}` : `${read}${withChecklist ? '/0' : ''}`
    const counterErrorItems = decodedItems.filter((item) => item.item?.status === 'error').length
    const counterWarningItems = decodedItems.filter((item) => item.item?.status === 'warning').length
    return (
      <Box row>
        {this.state.modalVisible && (
          <ProductItemsModal
            onClose={() => this.managerModal()}
            isOpen={this.state.modalVisible}
            product={product}
            decodedItems={decodedItems}
            onRemoveItem={onRemoveItem}
            removeModeEnabled={removeModeEnabled}
            operation={operation}
            hideIdentifiersByType={hideIdentifiersByType}
            hideIdentifiersZones={hideIdentifiersZones}
            overrideProductInfoFields={overrideProductInfoFields}
          />
        )}
        <GroupedListRow
          noShadow={noShadow}
          flex={flex}
          onClick={() => (product && product.id ? this.managerModal() : {})}
          clickable
          growColumn
        >
          {product.code && AppStore.getShowProductImage() && (
            <Box center pt={8} pb={8} pr={0} pl={10}>
              <Image width={115} height={120} src={Products.getImageUrl(product)} />
            </Box>
          )}
          <GroupedProductRowInfo product={product} overrideProductInfoFields={overrideProductInfoFields} />
          <Box center mr={10} ml={0} mt={0}>
            {!hideCounter && (isNumber(detected) || isNumber(expected) || isNumber(unexpected)) && (
              <SmallCounter
                mr={0}
                ph={20}
                detected={detected}
                expected={expected}
                unexpected={unexpected}
                noChecklist={!withChecklist}
                status={status}
              >
                {counterValue}
              </SmallCounter>
            )}
            {counterErrorItems > 0 && (
              <Box
                center
                pv={5}
                mt={5}
                ph={5}
                mr={0}
                style={{ fontWeight: 'bold', fontSize: 14, width: '100%', backgroundColor: '#FF8474', borderRadius: 5 }}
              >
                {__UP(T.error.error)} ({counterErrorItems})
              </Box>
            )}
            {counterWarningItems > 0 && (
              <Box
                center
                pv={5}
                mt={5}
                mr={0}
                ph={5}
                style={{ fontWeight: 'bold', fontSize: 14, width: '100%', backgroundColor: '#FFEA67', borderRadius: 5 }}
              >
                {__UP(T.misc.warning)} ({counterWarningItems})
              </Box>
            )}
          </Box>
        </GroupedListRow>
        {removeModeEnabled && (
          <TrashContainer>
            <Button
              variant="error"
              circle
              onClick={() => {
                if (onRemoveItem && decodedItems) {
                  decodedItems.forEach(async (item) => {
                    onRemoveItem(item)
                  })
                }
              }}
            >
              <Icons.Trash />
            </Button>
          </TrashContainer>
        )}
      </Box>
    )
  }
}

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
  noChecklist?: boolean
  status?: TmrStatus
}>`
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  min-width: 100px;
  width: 100%;
  align-self: center;
  background-color: ${({ detected = 0, expected = 0, unexpected = 0, noChecklist = false, status }) => {
    if (noChecklist) return '#F0F0F0'
    switch (status) {
      case 'error':
        return '#FF8474'
      case 'warning':
        return '#FFEA67'
      case 'ignored':
        return '#DAE6ED'
      default:
        if (detected + unexpected > expected) return '#F2B57D'
        if (noChecklist) return '#75EBA8'
        if (unexpected > 0) return '#F2B57D'
        if (detected < expected) return '#EDEDED'
        if (detected === expected) return '#75EBA8'
        return '#F0F0F0'
    }
  }};
  border-radius: 10px;
`

const TrashContainer = styled(Box)`
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`
