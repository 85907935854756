import { datadogRum } from '@datadog/browser-rum'
import { Component } from 'react'
import {
  ConfigurationDetail,
  DatadogEncodingRun,
  EncodingCounters,
  Encodings,
  EncodingValidationRequest,
  EncodingValidationResponse,
  IdentifierType,
  ItemConfigurations,
  TmrTag,
} from 'stylewhere/api'
import {
  Box,
  EncodingForm,
  EncodingIdentifers,
  EncodingProduct,
  EncodingReading,
  EncodingRightHeader,
  FeedbackModal,
  FullLoadingLayer,
  InputModal,
  Page,
  Text,
  WorkstationEmulationInfoBox,
} from 'stylewhere/components'
import { EncodingExtensions } from 'stylewhere/extensions'
import {
  AppStore,
  FormSchemaData,
  OperationReadingProps,
  RemoteOperation,
  RfidReader,
  Router,
  Sounds,
} from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import {
  askUserConfirmation,
  BLOCKED_ERRORS,
  enabledWamGroupedProduct,
  encodingComputeTagsEntityCode,
  getAskUserInputEveryTime,
  getAutomaticRestartAntenna,
  getCanForceAssocation,
  getEnableUhfWrite,
  getEncodingAssociationStatus,
  getEncodingProductFields,
  getEncodingTagPassword,
  getInitialType,
  getTagType,
  getTimeoutValidateTags,
  getUhfTagToWriteRegex,
  getWithUserConfirmationMessage,
  isExpectOnlyMandatoryIdentifiers,
  isModalError,
  isRfidAndSerial,
  isSkipValidation,
  MAX_WRITE_ATTEMPT,
  showToast,
  showToastError,
  sleep,
  uuid,
} from 'stylewhere/shared/utils'

export interface EncodingState {
  encodingValidation?: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  encodingCreateResponse?: EncodingValidationResponse
  formSchemaData?: FormSchemaData
  options: {
    value: string
    label: string
    active: boolean
  }[]
  counters?: EncodingCounters
  tagsRead: TmrTag[]
  processing: boolean | 'nfcDecryption'
  associationStatus: 'TO_BE_READ' | 'CONFIRMED' | 'ERROR' | 'PROCESSING' | 'IGNORED' | 'SKIPPED' | 'WRITING'
  showPin: boolean
  antennaOn: boolean
  forceAssociation: boolean
  pin: string
  configuration: any[]
  encodingCreateCounter: number
  targetTag: string
  targetTid: string
  tagToWrite: string
  serial: string
  numWriteAttempt: number
  starting: boolean
  resetFormCounter: number
}

export default class EncodingEncode<
  P extends OperationReadingProps<EncodingState>,
  S extends EncodingState
> extends Component<P, S> {
  lastSerial
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<EncodingState>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  initialType = getInitialType(this.operation)
  datadogSession: DatadogEncodingRun | undefined = undefined

  getInitFormSchemaData = () => {
    return this.initialType === 'formSchema' &&
      this.operation.formSchema &&
      Object.keys(this.operation.formSchema).length > 0
      ? {}
      : { product: { code: '' }, wam: '' }
  }

  public state: S = {
    options: [
      { value: 'associate', label: __(T.misc.associate), active: true },
      { value: 'verify', label: __(T.misc.verify), active: false },
    ],
    formSchemaData: this.getInitFormSchemaData(),
    tagsRead: [],
    processing: false,
    associationStatus: 'TO_BE_READ',
    showPin: false,
    antennaOn: false,
    pin: '',
    forceAssociation: false,
    configuration: [],
    encodingCreateCounter: 0,
    targetTag: '',
    targetTid: '',
    tagToWrite: '',
    serial: '',
    numWriteAttempt: 0,
    starting: false,
    resetFormCounter: 1,
  } as any

  isEncoding = false
  encodingStart
  isModal = false
  timer: NodeJS.Timeout | null = null
  timerReader?: any
  timerTags: NodeJS.Timeout | null = null
  timerNoRead: NodeJS.Timeout | null = null

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    if (this.initialType === 'identifier') {
      if (!isSkipValidation(this.operation)) {
        this.setState({ starting: true }, this.initializeReader)
      } else {
        //get itemConfiuguration
        const config = await EncodingExtensions.getItemTypeConfiguration(this.operation)
        if (config) {
          const encodingValidation: EncodingValidationRequest = {
            identifiers: [],
            operationId: this.operation.id,
            placeId: AppStore.defaultWorkstation!.placeId,
          }
          this.setState(
            { configuration: config, forceAssociation: true, encodingValidation: encodingValidation },
            this.initializeReader
          )
        } else {
          showToastError(__(T.error.no_item_configuration), __(T.error.error), this.isModal)
        }
      }
    } else {
      this.initializeReader()
    }
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      this.setState({ starting: true })
      await RfidReader.stop()
    }
  }

  updateFormSchemaData = (formSchemaData: FormSchemaData) => {
    this.setState({ formSchemaData })
  }

  initializeDataDogSession = async (formSchemaData?: FormSchemaData) => {
    const isActive = await AppStore.activeDatadog()
    if (isActive) {
      this.datadogSession = {
        runId: uuid().toString(),
        startTime: new Date(),
        initialTypeRequested: {
          label: EncodingExtensions.getDataDogFormSchemaLabel(this.operation, formSchemaData, this.initialType),
          type: this.initialType,
          offsetMs: 0,
        },
        errors: [],
      }
      datadogRum.addAction('startEncoding', this.datadogSession)
    }
  }

  getUpdatedCounters = (success) => {
    const tmp = this.state.counters
    if (tmp && success) {
      if (tmp.order) {
        tmp.order.encoded = (tmp.order.encoded ?? 0) + 1
      }
      if (tmp.row) {
        tmp.row.encoded = (tmp.row.encoded ?? 0) + 1
      }
    }
    return tmp
  }

  onSubmitForm = async (formSchemaData: FormSchemaData) => {
    const { resetFormCounter } = this.state
    try {
      const res = await EncodingExtensions.getItemConfiguration(this.operation, formSchemaData)
      this.initializeDataDogSession(formSchemaData)
      this.setState(
        {
          encodingValidation: res.encodingValidation,
          encodingValidationResponse: res.encodingValidationResponse,
          counters: res.counters,
          formSchemaData,
        },
        this.startReader
      )
    } catch (error) {
      this.setState({ resetFormCounter: resetFormCounter + 1 })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  getUseTidTargetDuringWrite = () => {
    return (
      this.operation.options &&
      ((this.operation.options.useTidTargetDuringWrite && this.operation.options.useTidTargetDuringWrite === 'true') ||
        !this.operation.options.useTidTargetDuringWrite)
    )
  }

  getIgnoreUnknownTags = () => {
    const { options } = this.operation
    return options && options.ignoreUnknownTags && options.ignoreUnknownTags === 'yes'
  }

  onBeforeInitializeReader() {
    // extensible
  }

  initializeReader = async () => {
    try {
      this.onBeforeInitializeReader()
      await RfidReader.initialize()
      if (this.initialType === 'identifier') {
        RfidReader.setAutomaticStop(false)
      } else {
        RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
        RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
      }
      RfidReader.setIgnoreReadingTagsWithPrefixes(
        this.operation.options && this.operation.options.ignoreReadingTagsWithPrefixes
          ? this.operation.options.ignoreReadingTagsWithPrefixes
          : ''
      )
      RfidReader.setOnTagReadCallback(this.initialType === 'identifier' ? this.onTagReadIdentifier : this.onTagRead)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      if (this.initialType === 'identifier') {
        await this.startReader()
      }
    } catch (error) {
      if (!AppStore.getEmulation()) {
        showToastError(
          (error as any).message ?? __(T.error.rfid_reader_initialization),
          __(T.error.error),
          this.isModal
        )
      }
    }
  }

  startReader = async () => {
    try {
      // await this.initializeReader()
      await RfidReader.start(undefined, undefined, undefined, getAutomaticRestartAntenna(this.operation))
    } catch (error) {
      if (!AppStore.getEmulation()) {
        showToastError(
          (error as any).message ?? __(T.error.rfid_reader_initialization),
          __(T.error.error),
          this.isModal
        )
      }
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true, starting: false })
  }

  onStopCallbackIdentifierCheck = (tmpEncoding: EncodingValidationRequest) => {
    return (
      tmpEncoding && tmpEncoding.identifiers && !tmpEncoding.identifiers.every((idt) => idt._status === 'CONFIRMED')
    )
  }

  onStopCallback = () => {
    if (!AppStore.getEmulation()) {
      const tmpEncoding = this.state.encodingValidation
      if (this.initialType !== 'identifier') {
        RfidReader.setAutomaticStop(false)
        RfidReader.stopTimer()
      }
      if (tmpEncoding && this.onStopCallbackIdentifierCheck(tmpEncoding)) {
        tmpEncoding.identifiers
          .filter((id) => id.identifierType !== 'SIMPLE_ITEM_IDENTIFIER')
          .filter((id) => !id._read)
          .filter((id) => id._status !== 'ERROR' && id._status !== 'NFC_COLLISION' && id._status !== 'SKIPPED')
          .forEach((idf) => {
            idf._status = 'ERROR'
            idf._error = __(T.imbustatrice.dynamic_tag_missing, { role: idf.role })
          })
        this.setState({
          antennaOn: false,
          starting: false,
          associationStatus: 'ERROR',
          encodingValidation: tmpEncoding,
        })
      } else {
        this.setState({ antennaOn: false, starting: false })
      }
    } else {
      this.setState({ antennaOn: false, starting: false })
    }
  }

  clearTimerReader = () => {
    if (this.timerReader) {
      clearTimeout(this.timerReader)
      this.timerReader = null
    }
  }

  attachAutomaticReaderStop = () => {
    if (this.operation.autostopAntennaTimeout > 0) {
      this.clearTimerReader()
      this.timerReader = setTimeout(async () => {
        if (!this.isEncoding) {
          await this.stopAntenna()
          this.isEncoding = true
          this.encodingStart = new Date()
          if (this.checkIfForceCreate('yes')) {
            this.setState(
              { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
              this.create
            )
          } else if (this.checkIfForceCreate('withUserConfirmation')) {
            const { encodingValidationResponse } = this.state
            const errors =
              encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
            // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
            if (
              errors.length == 1 &&
              errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
              isExpectOnlyMandatoryIdentifiers(this.operation)
            ) {
              this.call_force_encoding(false)
            } else {
              const ok = await askUserConfirmation(
                __(T.misc.attention),
                getWithUserConfirmationMessage(errors, encodingValidationResponse)
              )
              if (ok) {
                this.call_force_encoding(false)
              } else {
                this.clear()
                this.isEncoding = false
              }
            }
          } else {
            this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
          }
        }
      }, this.operation.autostopAntennaTimeout)
    }
  }

  componentWillUnmount = async () => {
    this.setState({ processing: false })
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.clearTimerReader()
    this.clearTimer()
    await this.stopAntenna()
  }

  matchConfigurationTag = (identifiers) => {
    const { configuration } = this.state
    // console.log(configuration)
    // console.log(identifiers)
    return false
  }

  verifySerial = async (tag: TmrTag) => {
    this.lastSerial = tag
    this.setState({ processing: true }, this.executeVerify)
  }

  executeVerify = async () => {
    try {
      const tmpEncodingValidation: EncodingValidationRequest = {
        identifiers: [],
        operationId: this.operation.id,
        placeId: AppStore.defaultWorkstation!.placeId,
      }
      await EncodingExtensions.onTagRead(tmpEncodingValidation, this.lastSerial, this.operation)
      const encode = await EncodingExtensions.verify(tmpEncodingValidation)
      if (
        encode &&
        encode.encodingValidationResponse &&
        encode.encodingValidationResponse.item &&
        encode.encodingValidationResponse.item.identifiers
      ) {
        const idt = encode.encodingValidationResponse.item.identifiers.find((e) => e.type === 'UHF_TAG')
        if (idt) {
          this.lastSerial = null
          const tmpTag = { type: 'UHF_TAG', epc: idt.code }
          const tmpTagsRead = this.state.tagsRead
          tmpTagsRead.push(tmpTag as TmrTag)
          tmpEncodingValidation.identifiers = []
          this.setState(
            { encodingValidation: tmpEncodingValidation, tagsRead: tmpTagsRead },
            this.validateIdentifiersTag
          )
        } else {
          this.setState({ processing: false })
          this.removeTagReadFromReader([this.lastSerial])
          this.lastSerial = null
          showToastError('Unknown error during encoding validation', __(T.error.error), this.isModal)
        }
      } else {
        this.setState({ processing: false })
        this.removeTagReadFromReader([this.lastSerial])
        this.lastSerial = null
        showToastError('Unknown error during encoding validation', __(T.error.error), this.isModal)
      }
    } catch (error) {
      this.setState({ processing: false })
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  onTagReadIdentifier = async (tag: TmrTag) => {
    const { encodingValidation, encodingCreateCounter } = this.state
    const tmpTagsRead = this.state.tagsRead
    const identifierType = getTagType(tag)
    if (isSkipValidation(this.operation)) {
      tmpTagsRead.push(tag)
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      const matches = this.matchConfigurationTag(encodingValidation?.identifiers)
      if (matches) {
        //await this.stopAntenna()
        this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
      } else {
        this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerNoReads)
      }
    } else {
      if (!encodingValidation && identifierType === 'UHF_TAG') {
        tmpTagsRead.push(tag)
        this.setState({ tagsRead: tmpTagsRead }, this.startTimerTags)
      } else {
        tmpTagsRead.push(tag)
        if (encodingValidation) {
          await EncodingExtensions.onTagRead(encodingValidation, tag, this.operation)
          this.setState({ tagsRead: tmpTagsRead, encodingValidation: encodingValidation }, this.startTimerTags)
        } else {
          this.setState({ tagsRead: tmpTagsRead })
        }
      }
    }
  }

  validateIdentifiersTag = async () => {
    const { tagsRead } = this.state
    //await this.stopAntenna()
    const tmpEncodingValidation: EncodingValidationRequest = {
      identifiers: [],
      operationId: this.operation.id,
      placeId: AppStore.defaultWorkstation!.placeId,
    }
    for (let t = 0; t < tagsRead.length; t++) {
      await EncodingExtensions.onTagRead(tmpEncodingValidation, tagsRead[t], this.operation)
    }
    try {
      const res = await EncodingExtensions.validate(
        tmpEncodingValidation,
        false,
        false,
        getEnableUhfWrite(this.operation) !== 'no'
      )
      if (!this.datadogSession)
        this.initializeDataDogSession({ identifier: tagsRead[0].epc ?? tagsRead[0].uid ?? tagsRead[0].barcode })
      if (
        res.encodingValidationResponse.item &&
        res.encodingValidationResponse.item.product &&
        res.encodingValidationResponse.item.product !== null
      ) {
        await this.addIdentifierRole(res)
        await this.addIdentifierConfiguration(res)
        if (res.encodingValidationResponse.success) {
          this.isEncoding = true
          this.encodingStart = new Date()
        }
        this.setState(
          {
            encodingValidationResponse: res.encodingValidationResponse,
            encodingValidation: res.encodingValidation,
            processing: res.encodingValidationResponse.success,
          },
          res.encodingValidationResponse.success ? this.onTagReadValidateResponse : this.startTimerNoReads
        )
      } else {
        this.setState({ processing: false })
        await this.stopAntenna()
        this.removeTagReadFromReader(tagsRead)
        let modalText = __(T.error.tags_errors)
        if (res && res.encodingValidationResponse && res.encodingValidationResponse.errors) {
          let errorCheck = res.encodingValidationResponse.errors.find((e) => e.errorCode === 'MISSING_PRODUCT_INFO')
          if (errorCheck) {
            modalText = __(T.error.only_virgin_tag)
          } else if (
            (errorCheck = res.encodingValidationResponse.errors.find(
              (e) => e.errorCode === 'Tag Mismatch' || e.errorCode === 'TAG_MISMATCH'
            ))
          ) {
            modalText = __(T.error.tags_mismatch_error)
          } else if (
            (errorCheck = res.encodingValidationResponse.errors.find((e) => e.errorCode === 'NFC_TAG_NOT_EXPECTED'))
          ) {
            modalText = __(T.error.nfc_tag_not_expected)
          }
          showToastError(modalText, __(T.error.error), true, this.closeVirginTagModal, 'OK')
          this.feedbackSoundKo()
        }
        showToastError(modalText, __(T.error.error), true, this.closeVirginTagModal, 'OK')
        this.feedbackSoundKo()
      }
    } catch (error) {
      this.setState({ processing: false })
      this.removeTagReadFromReader(tagsRead)
      showToastError(
        (error as any).message ?? 'Unknown error during encoding validation',
        __(T.error.error),
        this.isModal
      )
    }
  }

  closeVirginTagModal = async () => {
    this.clearTimer()
    this.clearTimerTags()
    this.clearTimerNoReads()
    await this.startAntenna()
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      counters: undefined,
      tagsRead: [],
      associationStatus: 'TO_BE_READ',
      pin: '',
      forceAssociation: false,
      encodingCreateCounter: 0,
    })
  }

  removeTagReadFromReader = (tags: TmrTag[]) => {
    const toRemove: string[] = []
    for (let t = 0; t < tags.length; t++) {
      toRemove.push(tags[t].uid ?? tags[t].epc)
    }
    RfidReader.removeTags(toRemove)
  }

  clearTimerNoReads = () => {
    if (this.timerNoRead) {
      clearTimeout(this.timerNoRead)
      this.timerNoRead = null
    }
  }

  startTimerNoReads = async () => {
    const { encodingValidation } = this.state
    if (encodingValidation) {
      if (this.checkIfForceCreate('yes')) {
        this.isEncoding = true
        this.encodingStart = new Date()
        this.setState(
          { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
          this.create
        )
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const { encodingValidationResponse } = this.state
        const errors =
          encodingValidationResponse && encodingValidationResponse.errors ? encodingValidationResponse.errors : []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.operation)
        ) {
          this.call_force_encoding(false)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(false)
          } else {
            this.clear()
            this.isEncoding = false
          }
        }
      } else {
        //if (!isSkipValidation(this.operation)) await this.startAntenna()
        const timer = this.operation.options.timeoutTagsNoReads ? this.operation.options.timeoutTagsNoReads : 5000
        if (timer > 0) {
          this.clearTimerNoReads()
          this.timerNoRead = setTimeout(async () => {
            //await this.stopAntenna()
            if (isSkipValidation(this.operation)) {
              this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
            } else {
              this.feedbackSoundKo()
              this.setState({ associationStatus: 'ERROR' })
            }
          }, timer)
        }
      }
    }
  }

  addIdentifierRole = async (res) => {
    let idt
    if (
      res.encodingValidationResponse &&
      res.encodingValidationResponse.item &&
      res.encodingValidationResponse.item.identifiers
    ) {
      for (let i = 0; i < res.encodingValidationResponse.item.identifiers.length; i++) {
        idt = res.encodingValidation.identifiers.find(
          (e) => e.code === res.encodingValidationResponse.item.identifiers[i].code
        )
        if (idt) {
          idt.role = res.encodingValidationResponse.item.identifiers[i].role ?? ''
        }
      }
    }
  }

  addIdentifierConfiguration = async (res) => {
    let config
    if (res.encodingValidationResponse.configurationDetails)
      config = res.encodingValidationResponse.configurationDetails
    else if (res.encodingValidationResponse.configuration && res.encodingValidationResponse.configuration.details)
      config = res.encodingValidationResponse.configuration.details
    if (!config) config = []

    // added role if not present for each identifier based on configuration
    let cg
    for (let r = 0; r < res.encodingValidation.identifiers.length; r++) {
      if (!res.encodingValidation.identifiers[r].role) {
        cg = config.find((o) => o.identifierType === res.encodingValidation.identifiers[r].identifierType)
        if (cg) {
          res.encodingValidation.identifiers[r].role = cg.role
        }
      }
    }

    // filter identifiers only for status != IGNORED
    const identifiers = res.encodingValidation.identifiers.filter((e) => e._status !== 'IGNORED')

    const expectOnlyMandatoryIdentifiers = isExpectOnlyMandatoryIdentifiers(this.operation)
    let idt, serial, code, status, read
    for (let c = 0; c < config.length; c++) {
      if (!expectOnlyMandatoryIdentifiers || (expectOnlyMandatoryIdentifiers && !config[c].optional)) {
        idt = identifiers.find(
          (e) => e.identifierType === config[c].identifierType && e.role.toLowerCase() === config[c].role.toLowerCase()
        )
        if (!idt) {
          status = 'TO_BE_READ' //'PROCESSING'
          code = ''
          read = false
          //check if there is a serial number among the item identifiers
          if (config[c].identifierType === 'SIMPLE_ITEM_IDENTIFIER') {
            serial = res.encodingValidationResponse.item.identifiers.find(
              (e) => e.type === config[c].identifierType && e.role.toLowerCase() === config[c].role.toLowerCase()
            )
            if (serial && serial.code !== '') {
              status = 'CONFIRMED' //serial.status
              code = serial.code
              read = true
            }
          }

          res.encodingValidation.identifiers.push({
            code: code,
            identifierType: config[c].identifierType,
            role: config[c].role,
            _status: status,
            _read: read,
            _error: undefined,
          })
        }
      }
    }
  }

  clearTimerTags = () => {
    if (this.timerTags) {
      clearTimeout(this.timerTags)
      this.timerTags = null
    }
  }

  startTimerTags = () => {
    const timeoutValidateTags = this.operation.options.timeoutValidateTags ?? 1000
    this.clearTimerNoReads()
    this.clearTimerTags()
    this.timerTags = setTimeout(async () => {
      if (this.state.encodingValidation) {
        //await this.stopAntenna()
        this.validateReadTags()
      } else {
        this.validateIdentifiersTag()
      }
    }, timeoutValidateTags)
  }

  validateReadTags = async () => {
    if (this.isEncoding) return
    this.isEncoding = true
    this.encodingStart = new Date()
    const tmpTagsRead = this.state.tagsRead
    const tmpEncodingValidation = this.state.encodingValidation
    if (tmpEncodingValidation) {
      for (let t = 0; t < tmpTagsRead.length; t++) {
        await EncodingExtensions.onTagRead(tmpEncodingValidation, tmpTagsRead[t], this.operation)
      }
      try {
        const res = await EncodingExtensions.validate(
          tmpEncodingValidation,
          true,
          false,
          getEnableUhfWrite(this.operation) !== 'no'
        )
        if (!this.datadogSession)
          this.initializeDataDogSession({
            identifier: tmpTagsRead[0].epc ?? tmpTagsRead[0].uid ?? tmpTagsRead[0].barcode,
          })
        this.setState(
          {
            encodingValidationResponse: res.encodingValidationResponse,
            encodingValidation: tmpEncodingValidation,
          },
          this.onTagReadValidateResponse
        )
      } catch (error) {
        this.isEncoding = false
        this.onClear()
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.isEncoding = false
      this.onClear()
    }
  }

  checkIfForceCreate = (value) => {
    const { encodingValidationResponse } = this.state
    let canForce = false
    if (getCanForceAssocation(this.operation) === value) {
      const notForcible = encodingValidationResponse?.errors.find((e) => !e.forcible)
      //if only errors with forcible = true then force create
      canForce = !notForcible
    }
    return canForce
  }

  call_force_encoding = (write) => {
    const { encodingCreateCounter } = this.state
    if (!write) {
      this.isEncoding = true
      this.encodingStart = new Date()
      this.setState({ forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
    } else {
      this.setState(
        { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
        this.checkWriteTag
      )
    }
  }

  checkRestartAntenna = async () => {
    const { encodingValidation } = this.state
    let restart = true
    const identifiers = encodingValidation?.identifiers ?? []
    let i = 0
    while (i < identifiers.length && restart) {
      if (identifiers[i]._status === 'ERROR') restart = false
      i++
    }
    if (restart) {
      await this.startAntenna()
    }
  }

  additionalOnTagReadCheck = (tag: TmrTag) => {
    return true
  }

  onTagRead = async (tag: TmrTag) => {
    //added a sleep to destroy correctly the timers or wait isEncoding flag
    await sleep(Math.random() * 500)

    const { encodingValidation } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return
    if (this.isEncoding) {
      this.removeTagReadFromReader([tag])
      return
    }
    if (!this.additionalOnTagReadCheck(tag)) return
    // if time to validate is 0 then set isEncoding to true immediately for not have validated in parallel
    const timeoutValidateTags = getTimeoutValidateTags(this.operation, this.getIgnoreUnknownTags())
    if (timeoutValidateTags !== undefined && timeoutValidateTags === 0) {
      this.isEncoding = true
    }
    try {
      RfidReader.setAutomaticStop(false)
      RfidReader.stopTimer()
      this.clearTimerReader()
      this.clearTimer()
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      this.setState({ encodingValidation }, () => {
        if (this.datadogSession) {
          if (tag.uid) {
            this.datadogSession.nfcRead = {
              offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
              nfcRead: tag.uid,
            }
            datadogRum.addAction('nfcRead', this.datadogSession)
          } else if (tag.epc) {
            this.datadogSession.uhfRead = {
              offsetMs: Date.now() - this.datadogSession.startTime.getTime(),
              uhfRead: tag.epc,
            }
            datadogRum.addAction('uhfRead', this.datadogSession)
          }
        }
        this.onTagReadTimer()
      })
    } catch (error) {}
  }

  onTagReadTimer = () => {
    const timeoutValidateTags = getTimeoutValidateTags(this.operation, this.getIgnoreUnknownTags())
    if (timeoutValidateTags !== undefined && timeoutValidateTags > 0) {
      this.timer = setTimeout(() => {
        this.onTagReadValidate()
      }, timeoutValidateTags)
    } else {
      this.onTagReadValidate()
    }
  }

  onTagReadValidate = async () => {
    try {
      this.isEncoding = true
      this.encodingStart = new Date()
      const res = await EncodingExtensions.validate(
        this.state.encodingValidation!,
        true,
        false,
        getEnableUhfWrite(this.operation) !== 'no'
      )
      this.setState(
        {
          encodingValidationResponse: res.encodingValidationResponse,
          encodingValidation: res.encodingValidation,
        },
        this.onTagReadValidateResponse
      )
    } catch (error) {
      this.clearReads(false, true)
      this.encodingError(error, 'Unknown error during encoding validation')
    } finally {
      this.isEncoding = false
    }
  }

  feedbackSoundConfirmation = () => {
    const { options } = this.operation
    if (options.enableSoundFeedbackConfirm && options.enableSoundFeedbackConfirm === 'true') {
      Sounds.confirmation()
    }
  }

  feedbackSoundOk = () => {
    const { options } = this.operation
    if (options.enableSoundFeedbackOk && options.enableSoundFeedbackOk === 'true') {
      Sounds.success()
    }
  }

  feedbackSoundKo = () => {
    const { options } = this.operation
    if (options.enableSoundFeedbackKo && options.enableSoundFeedbackKo === 'true') {
      Sounds.error()
    }
  }

  encodingFail = () => {
    this.setState({ associationStatus: 'ERROR', processing: false })
    this.feedbackSoundKo()
    this.isEncoding = false
  }

  encodingError = (error?: any, text?: string) => {
    this.feedbackSoundKo()
    showToastError(
      error && error.message ? error.message : text ?? 'Unknown error during encoding validation',
      __(T.error.error),
      this.isModal
    )
  }

  onValidationOperationNone = async () => {
    await this.stopAntenna()
    this.setState({ processing: false, associationStatus: 'SKIPPED' })
    if (this.operation.options.onItemAlreadyEncodedFeedback === 'confirmModal') {
      AppStore.showFeedbackModal(
        __(T.misc.attention),
        __(T.messages.encoding_success_none),
        () => {},
        __(T.misc.confirm)
      )
    } else {
      showToast({ status: 'success', description: __(T.messages.encoding_success_none) })
    }
    this.feedbackSoundOk()
    setTimeout(async () => {
      this.resetAfterCreate()
    }, this.operation.options.resetReadingsAfterOperation || 300)
  }

  sendMixpanelEventData = async (event) => {
    const { encodingValidation, encodingValidationResponse, forceAssociation } = this.state
    const endDate = new Date()
    const seconds = this.encodingStart ? (endDate.getTime() - this.encodingStart.getTime()) / 1000 : 0
    const mixpanelData = EncodingExtensions.getMixPanelData(
      this.operation,
      encodingValidation,
      encodingValidationResponse,
      seconds,
      forceAssociation ? 'yes' : 'no'
    )
    AppStore.sendMixPanelEvent(event, mixpanelData)
  }

  checkValidateAfterRemoveUnknownTags = () => {
    const { encodingValidation } = this.state
    if (encodingValidation) {
      const findCode = encodingValidation.identifiers.find((idt) => idt.code && idt.code !== '')
      if (findCode) {
        this.onTagReadValidate()
      } else {
        this.isEncoding = false
      }
    } else {
      this.isEncoding = false
    }
  }

  // callback response validate on flow product and order
  onTagReadValidateResponse = async () => {
    const { encodingValidationResponse, encodingValidation, encodingCreateCounter } = this.state
    if (encodingValidationResponse) {
      // remove ignored tags if request from operation option
      if (
        this.getIgnoreUnknownTags() &&
        encodingValidation &&
        encodingValidationResponse.errors.filter((el) => el.errorCode === 'UNKNOWN_TAG').length > 0
      ) {
        let resetIdentifiers = false
        const identifiers = encodingValidation.identifiers ?? []
        const newIdentifiers: ConfigurationDetail[] = []
        let find
        identifiers.map((idt) => {
          const checkUnknown = encodingValidationResponse.errors.find(
            (el) => el.errorCode === 'UNKNOWN_TAG' && el.ref === idt.code
          )
          if (!checkUnknown) {
            if (idt._added) {
              // to management case with code not associated at same identifierType
              find = identifiers.find((f) => !f._added && idt.identifierType === f.identifierType)
              if (find) {
                newIdentifiers.push({
                  id: find.id ?? '',
                  creationDate: find.creationDate ?? '',
                  identifierType: find.identifierType,
                  role: find.role ?? '',
                  optional: find.optional ?? false,
                  regexFilter: find.regexFilter ?? '',
                  code: idt.code,
                  _status: idt.code && idt.code !== '' ? 'CONFIRMED' : 'PROCESSING',
                })

                resetIdentifiers = true
              } else {
                resetIdentifiers = true
                newIdentifiers.push(idt)
              }
            } else {
              resetIdentifiers = true
              newIdentifiers.push(idt)
            }
          } else if (!idt._added) {
            resetIdentifiers = true
            newIdentifiers.push({
              id: idt.id ?? '',
              creationDate: idt.creationDate ?? '',
              identifierType: idt.identifierType,
              role: idt.role ?? '',
              optional: idt.optional ?? false,
              regexFilter: idt.regexFilter ?? '',
            })
          }
        })

        if (resetIdentifiers) {
          const finalIdentifiers: ConfigurationDetail[] = newIdentifiers.filter((f) => f.code && f.code !== '')
          newIdentifiers.find((idt) => {
            find = finalIdentifiers.find((f) => f.role === idt.role && f.identifierType === idt.identifierType)
            if (!find) {
              finalIdentifiers.push(idt)
            }
          })
          this.setState(
            { encodingValidation: { ...encodingValidation, identifiers: finalIdentifiers } },
            this.checkValidateAfterRemoveUnknownTags
          )
          return
        }
      }

      if (encodingValidationResponse.errors && encodingValidationResponse.errors.length > 0) {
        // if there are EXTRA_TAGS errore we can return encoding error at operator
        if (encodingValidationResponse.errors.filter((el) => el.errorCode === 'EXTRA_TAG').length > 0) {
          this.encodingFail()
          return
        }

        // if there are MISSING_MANDATORY_TAG errors we are waiting for the other requested tags
        const checkMissing = encodingValidationResponse.errors.find((el) => el.errorCode === 'MISSING_MANDATORY_TAG')
        if (checkMissing) {
          this.isEncoding = false
          return
        }
      }

      if (
        encodingValidationResponse.operationToPerform === 'NONE' &&
        this.operation.options.forceTagRewrite !== 'true'
      ) {
        await this.sendMixpanelEventData('Encoding: Item Previously Associated')
        await this.onValidationOperationNone()
      } else if (
        encodingValidationResponse.operationToPerform === 'NONE' &&
        this.operation.options.forceTagRewrite === 'true'
      ) {
        this.forceTagRewrite()
      } else if (
        encodingValidationResponse.operationToPerform === 'UPDATE_ONLY_PRODUCT' ||
        encodingValidationResponse.operationToPerform === 'UPDATE_WAM'
      ) {
        this.feedbackSoundConfirmation()

        const readedTag = encodingValidation?.identifiers
          ? encodingValidation?.identifiers.length === 1
            ? encodingValidation?.identifiers[0]
            : encodingValidation?.identifiers?.length > 1
            ? encodingValidation?.identifiers.find((id) => id._status === 'CONFIRMED')
            : undefined
          : undefined
        const ok = await askUserConfirmation(
          __(T.misc.attention),
          __(T.misc.update_only_product),
          undefined,
          undefined,
          readedTag
            ? {
                identifierCode: readedTag.code,
              }
            : undefined
        )
        if (ok) {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.checkWriteTag)
        } else {
          if (getAskUserInputEveryTime(this.operation)) {
            await this.stopAntenna()
          }
          this.clear()
          this.isEncoding = false
          await this.sendMixpanelEventData('Encoding: Update product not confirmed')
        }
      } else if (encodingValidationResponse.operationToPerform === 'FAIL') {
        await this.sendMixpanelEventData('Encoding: error')
        this.encodingFail()
      } else if (encodingValidationResponse.operationToPerform === 'FORCE_ITEM_CREATION') {
        this.clearTimerReader()
        this.checkEncodingForceOption()
      } else {
        this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.checkWriteTag)
      }
    } else {
      await this.sendMixpanelEventData('Encoding: error')
      this.encodingFail()
    }
  }

  forceTagRewrite = async () => {
    try {
      const { encodingValidation, formSchemaData, numWriteAttempt, encodingValidationResponse } = this.state
      if (!encodingValidation?.identifiers?.length) throw new Error('Invalid tag read')
      if (encodingValidation?.identifiers?.length > 1) throw new Error('Read only one tag at time')
      await this.stopAntenna()

      const identifier = { code: encodingValidation.identifiers[0].code, identifierType: 'UHF_TAG' as IdentifierType }
      const res = await Encodings.computeTags(
        this.operation.id,
        [identifier],
        AppStore.defaultWorkstation!.id,
        '', //entity id
        encodingComputeTagsEntityCode(encodingValidationResponse, this.initialType, formSchemaData) //entity code
      )
      if (!res || res.length != 1 || !res[0]?.desiredCode) throw new Error('Generation of the tag to write failed')

      const tmpEncodingValidation = encodingValidation
      const identifierTag = tmpEncodingValidation.identifiers.find((idt) => idt.identifierType === 'UHF_TAG')
      if (!identifierTag || !identifierTag.code) throw new Error('UHF tag not found')
      identifierTag.desiredCode = res[0].desiredCode
      this.setState({ encodingValidation: tmpEncodingValidation }, async () => {
        const validateRes = await EncodingExtensions.validate(
          tmpEncodingValidation,
          true,
          true,
          getEnableUhfWrite(this.operation) !== 'no'
        )
        if (validateRes.encodingValidationResponse.operationToPerform !== 'REWRITE_CONFIGURATION')
          throw new Error('Validation failed for rewrite configuration')
        this.setState(
          {
            targetTag: identifierTag.code ?? '',
            targetTid: identifierTag.tid ?? '',
            associationStatus: 'WRITING',
            encodingValidationResponse: validateRes.encodingValidationResponse,
            tagToWrite: res[0].desiredCode,
            numWriteAttempt: numWriteAttempt + 1,
          },
          this.writeTag
        )
      })
    } catch (error) {
      this.encodingError(error)
      this.clear()
    }
  }

  checkEncodingForceOption = async () => {
    const { encodingValidationResponse } = this.state
    if (encodingValidationResponse) {
      const encodingWrite = this.initialType !== 'identifier'
      if (this.checkIfForceCreate('yes')) {
        this.call_force_encoding(encodingWrite)
      } else if (this.checkIfForceCreate('withUserConfirmation')) {
        const errors = encodingValidationResponse.errors ?? []
        // if only error with code MISSING_OPTIONAL_TAG and isExpectOnlyMandatoryIdentifiers do force create
        if (
          errors.length == 1 &&
          errors[0].errorCode === 'MISSING_OPTIONAL_TAG' &&
          isExpectOnlyMandatoryIdentifiers(this.operation)
        ) {
          this.call_force_encoding(encodingWrite)
        } else {
          const ok = await askUserConfirmation(
            __(T.misc.attention),
            getWithUserConfirmationMessage(errors, encodingValidationResponse)
          )
          if (ok) {
            this.call_force_encoding(encodingWrite)
          } else {
            this.clear()
            this.isEncoding = false
          }
        }
      } else {
        if (encodingWrite) {
          this.checkAttachAutomaticReaderStop(encodingValidationResponse)
        } else {
          const timer = this.operation.options.timeoutTagsNoReads ? this.operation.options.timeoutTagsNoReads : 5000
          if (timer > 0) {
            this.clearTimerNoReads()
            this.timerNoRead = setTimeout(async () => {
              //await this.stopAntenna()
              if (isSkipValidation(this.operation)) {
                this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
              } else {
                this.feedbackSoundKo()
                this.setState({ associationStatus: 'ERROR' })
              }
            }, timer)
          }
        }
      }
    } else {
      this.feedbackSoundKo()
      this.setState({ processing: false })
      this.isEncoding = false
    }
  }

  checkAttachAutomaticReaderStop = async (encodingValidationResponse) => {
    this.isEncoding = false
    if (this.operation.autostopAntennaTimeout > 0) {
      const blockedErrors = encodingValidationResponse
        ? encodingValidationResponse.errors.filter((e) => BLOCKED_ERRORS.includes(e.errorCode))
        : []
      if (blockedErrors.length === 0) {
        this.attachAutomaticReaderStop()
      } else {
        //cosa fare se EXTRA TAGS??
        //await this.stopAntenna()
        this.feedbackSoundKo()
        this.setState({ associationStatus: 'ERROR' })

        /*setTimeout(async () => {
          this.resetAfterCreate()
        }, this.operation.options.resetReadingsAfterOperation || 300)*/
      }
    }
  }

  checkWriteTag = async () => {
    const { encodingValidation } = this.state
    if (getEnableUhfWrite(this.operation) !== 'no') {
      await this.stopAntenna()

      //si prendono tag UHF
      const identifiers = encodingValidation?.identifiers || []
      const uhfIdentifiers: any[] = []
      let r = 0
      for (r = 0; r < identifiers.length; r++) {
        if (identifiers[r].identifierType === 'UHF_TAG') {
          uhfIdentifiers.push(identifiers[r])
        }
      }

      let identifierTag: any = undefined
      if (uhfIdentifiers.length === 1) {
        identifierTag = uhfIdentifiers[0]
      } else if (getUhfTagToWriteRegex(this.operation) !== '') {
        let error = false
        for (r = 0; r < uhfIdentifiers.length; r++) {
          if (uhfIdentifiers[r].code.match(getUhfTagToWriteRegex(this.operation))) {
            if (!identifierTag) {
              identifierTag = uhfIdentifiers[r]
            } else {
              error = true
            }
          }
        }
        if (error) {
          identifierTag = undefined
        }
      }

      if (identifierTag) {
        if (identifierTag.desiredCode) {
          this.create()
        } else {
          //se solo un tag uhf si chiede nuovo tag -> computeTags
          this.setState(
            { targetTag: identifierTag.code, targetTid: identifierTag.tid, associationStatus: 'WRITING' },
            this.computeTags
          )
        }
      } else {
        this.clear()
        showToastError('UHF tag to write not found', __(T.error.error), this.isModal)
      }
    } else {
      this.create()
    }
  }

  computeTags = async () => {
    const { targetTag, formSchemaData, numWriteAttempt, encodingValidationResponse, encodingValidation } = this.state
    try {
      const res = await Encodings.computeTags(
        this.operation.id,
        //[{ code: targetTag, identifierType: 'UHF_TAG' }],
        encodingValidation?.identifiers ?? [],
        AppStore.defaultWorkstation!.id,
        '', //entity id
        encodingComputeTagsEntityCode(encodingValidationResponse, this.initialType, formSchemaData) //entity code
      )
      //if (res && res.length == 1 && res[0].desiredCode) {
      let desiderCode = ''
      if (res) {
        const find = res.find((r) => r.identifierType === 'UHF_TAG')
        if (find) desiderCode = find.desiderCode
      }
      if (desiderCode !== '') {
        //si scrive il nuovo tag con la write del reader
        this.setState({ tagToWrite: res[0].desiredCode, numWriteAttempt: numWriteAttempt + 1 }, this.writeTag)
      } else {
        // errore
        showToastError('Generation of the tag to write failed', __(T.error.error), this.isModal)
      }
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  writeTag = async () => {
    const { targetTag, targetTid, tagToWrite, encodingValidation } = this.state
    try {
      const useTidToWrite = this.getUseTidTargetDuringWrite() && targetTid && targetTid !== '' ? true : false
      const ret: any = await RfidReader.writeEpc(
        useTidToWrite ? targetTid : targetTag,
        tagToWrite,
        getEncodingTagPassword(this.operation),
        useTidToWrite
      )
      if (ret.ok) {
        if (encodingValidation) {
          //update encodingValidation by adding attribute to the identifier
          const identifiers = encodingValidation?.identifiers || []
          const index = identifiers.findIndex((el) => el.code === targetTag)
          if (index >= 0) {
            encodingValidation.identifiers[index].desiredCode = tagToWrite
          }
          this.setState({ encodingValidation: encodingValidation, associationStatus: 'TO_BE_READ' }, this.create)
        }
      } else {
        this.writeTagError()
      }
    } catch (error) {
      this.writeTagError()
    }
  }

  writeTagError = async () => {
    const { numWriteAttempt } = this.state
    if (numWriteAttempt === MAX_WRITE_ATTEMPT) {
      const ok = await askUserConfirmation(__(T.misc.write_tag), __(T.misc.retry_write_tag))
      if (ok) {
        this.setState({ numWriteAttempt: 1 }, this.writeTag)
      } else {
        showToastError(__(T.error.write_tag), __(T.error.error), this.isModal)
        this.clear()
      }
    } else {
      this.setState({ numWriteAttempt: numWriteAttempt + 1 }, this.writeTag)
    }
  }

  create = async () => {
    const { forceAssociation, pin, encodingValidation, encodingValidationResponse, tagsRead, formSchemaData } =
      this.state
    if (encodingValidation) {
      await this.stopAntenna()
      const skipValidation = isSkipValidation(this.operation)
      try {
        let res
        if (forceAssociation)
          res = await EncodingExtensions.force_create(
            { ...encodingValidation, itemAttributes: formSchemaData.attributes ?? {} },
            pin
          )
        else
          res = await EncodingExtensions.create({
            ...encodingValidation,
            itemAttributes: formSchemaData.attributes ?? {},
          })
        if (!res) throw new Error(__(T.error.item_creation_error))

        const updatedCounters = this.getUpdatedCounters(res.success)
        if (!skipValidation) {
          this.setState({
            encodingCreateResponse: res,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
            counters: updatedCounters,
          })
        } else {
          await EncodingExtensions.set_identifiers_status(
            encodingValidation,
            res,
            getEnableUhfWrite(this.operation) !== 'no'
          )
          this.setState({
            encodingCreateResponse: res,
            encodingValidationResponse: res,
            encodingValidation: encodingValidation,
            processing: false,
            associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
            counters: updatedCounters,
          })
        }
        if (res.success) {
          if (this.datadogSession) {
            this.datadogSession.endTime = new Date()
            this.datadogSession.operationSuccessOffsetMs =
              this.datadogSession.endTime.getTime() - this.datadogSession.startTime.getTime()
            datadogRum.addAction('encodingSuccess', this.datadogSession)
          }
          await this.sendMixpanelEventData('Encoding: Item Associated')
          await AppStore.increaseDailyEncodedItems(this.operation.code)
          showToast({ status: 'success', description: __(T.messages.encoding_success) })
          this.feedbackSoundOk()
        } else {
          await this.sendMixpanelEventData('Encoding: error')
          this.encodingError(undefined, __(T.error.item_creation_error))
        }
        //if success reset operation after time in milliseconds
        if (res.success) {
          setTimeout(async () => {
            this.resetAfterCreate()
          }, this.operation.options.resetReadingsAfterOperation || (skipValidation ? 4000 : 300))
        } else {
          this.isEncoding = false
          if (!forceAssociation) {
            await this.startAntenna()
          }
          this.setState({ processing: false })
        }
      } catch (error) {
        if (getInitialType(this.operation) !== 'identifier') {
          this.setState({ processing: false })
          this.attachAutomaticReaderStop()
        } else {
          this.removeTagReadFromReader(tagsRead)
          this.closeVirginTagModal()
        }
        if (!forceAssociation) {
          await this.startAntenna()
        }
        this.isEncoding = false
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.setState({ processing: false })
      this.encodingError(undefined, __(T.error.item_creation_error))
    }
  }

  resetAfterCreate = async () => {
    if (getInitialType(this.operation) !== 'identifier') {
      this.clearReads(true)
    } else {
      this.onClear(true)
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  isCanForceAssociation = () => {
    const { encodingValidationResponse } = this.state
    if (encodingValidationResponse) {
      //se tutti errori forzabili
      const notForcible = encodingValidationResponse.errors.find((e) => !e.forcible)
      return !notForcible
    }
    return false
  }

  checkForceAssociation = () => {
    const { encodingCreateCounter } = this.state
    const canForceAssociation = getCanForceAssocation(this.operation)
    if (canForceAssociation === 'no') {
      this.clear()
    } else {
      if (this.isCanForceAssociation()) {
        if (canForceAssociation === 'withPin') {
          this.managerPinModal()
        } else if (canForceAssociation === 'afterRetry') {
          if (encodingCreateCounter === 1) {
            this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
          } else if (encodingCreateCounter === 2) {
            this.setState(
              { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
              this.create
            )
          } else {
            this.clear()
          }
        } else if (canForceAssociation === 'yes') {
          this.setState(
            { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
            this.create
          )
        } else {
          this.clear()
        }
      } else {
        this.clear()
      }
    }
  }

  clear = () => {
    if (getInitialType(this.operation) !== 'identifier') {
      this.clearReads()
    } else {
      this.onClear()
    }
  }

  onClear = async (addtags = false) => {
    const { encodingValidation } = this.state
    this.clearTimer()
    if (this.initialType !== 'identifier') {
      await this.stopAntenna()
    } else {
      this.clearTimerTags()
      this.clearTimerNoReads()
      await this.startAntenna()
    }
    this.datadogSession = undefined
    RfidReader.clear()
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }
    this.setState(
      {
        formSchemaData: this.getInitFormSchemaData(),
        tagsRead: [],
        associationStatus: 'TO_BE_READ',
        pin: '',
        forceAssociation: false,
        encodingCreateCounter: 0,
        processing: false,
      },
      this.resetValidateResponse
    )
    this.isEncoding = false
  }

  afterClearReads() {
    //extensible
  }

  clearReads = async (addtags = false, noask = false) => {
    const { encodingValidation, resetFormCounter, targetTag } = this.state

    // stop reading before clearing tags to avoid double readings
    getAskUserInputEveryTime(this.operation) && !noask && (await this.stopAntenna())

    this.clearTimer()
    RfidReader.clear()
    this.datadogSession = undefined
    if (addtags && encodingValidation && encodingValidation.identifiers) {
      RfidReader.tags = encodingValidation.identifiers
        .filter((idf) => !!idf.code)
        .map(
          (idf) =>
            ({
              uid: idf.identifierType === 'NFC_TAG' && idf.code,
              epc: idf.identifierType === 'UHF_TAG' && idf.code,
              tid: idf.tid || '',
            } as any)
        )
    }

    if (getAskUserInputEveryTime(this.operation) && !noask) {
      RfidReader.setAutomaticStop(false)
      await this.stopAntenna()
      this.setState(
        {
          formSchemaData: this.getInitFormSchemaData(),
          associationStatus: 'TO_BE_READ',
          tagsRead: [],
          resetFormCounter: resetFormCounter + 1,
          pin: '',
          forceAssociation: false,
          encodingCreateCounter: 0,
          numWriteAttempt: 0,
          targetTag: targetTag ?? '',
          targetTid: '',
          tagToWrite: '',
          serial: '',
          processing: false,
        },
        this.resetValidateResponse
      )
    } else {
      //reset automatic stop antenna
      await this.startAntenna()
      RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
      if (this.initialType === 'product' && (this.state.formSchemaData.product.code as string).trim().length === 0)
        return

      //ricalcolare in base alla configurazione il reset
      const tmpEncoding = this.state.encodingValidation
      const identifiers = EncodingExtensions.resetIdentifiersEncodingValition(
        this.operation,
        this.state.encodingValidationResponse,
        this.state.encodingValidation
      )
      if (tmpEncoding) tmpEncoding.identifiers = identifiers

      this.setState({
        encodingValidation: tmpEncoding,
        encodingCreateResponse: undefined,
        associationStatus: 'TO_BE_READ',
        tagsRead: [],
        pin: '',
        forceAssociation: false,
        encodingCreateCounter: 0,
        numWriteAttempt: 0,
        targetTag: targetTag ?? '',
        targetTid: '',
        tagToWrite: '',
        serial: '',
        processing: false,
      })
    }
    this.isEncoding = false
    this.afterClearReads()
  }

  resetValidateResponse = () => {
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      counters: undefined,
      encodingCreateResponse: undefined,
    })
  }

  managerPinModal = () => {
    this.setState({ showPin: !this.state.showPin })
  }

  forceWithPin = (pin) => {
    this.setState({ pin: pin, forceAssociation: true, showPin: false }, this.create)
  }

  onTabClick = async (tab) => {
    if (tab === 'verify' && !this.isEncoding) {
      this.setState({ processing: true }, this._changeTab)
    }
  }

  _changeTab = async () => {
    RfidReader.clear()
    await this.stopAntenna()
    this.timer = setTimeout(() => {
      Router.navigate(`/encoding/:opCode/verify`, { opCode: this.operation.code })
    }, this.operation.options.antennaTurnWaitingInterval ?? 1000)
  }

  getCustomHeader(): JSX.Element | null {
    return null
  }

  getDisableRetry() {
    return false
  }

  getDisableInput() {
    return false
  }

  getProcessingMessage() {
    return __(T.messages.operation_in_progress)
  }

  getFullWidthAssociationBox() {
    return false
  }

  render() {
    const {
      encodingValidation,
      showPin,
      formSchemaData,
      encodingValidationResponse,
      counters,
      processing,
      associationStatus,
      antennaOn,
      starting,
      resetFormCounter,
    } = this.state
    const identifiers = encodingValidation?.identifiers
    const hasRfidAndSerial = isRfidAndSerial(this.operation) && !isSkipValidation(this.operation)
    const disabledClear = associationStatus === 'WRITING'
    return (
      <Page
        headerRight={
          <EncodingRightHeader
            onTabClick={this.onTabClick}
            antennaOn={antennaOn}
            operation={this.operation}
            options={this.state.options}
          >
            {this.getCustomHeader()}
          </EncodingRightHeader>
        }
        title={this.operation.description}
        enableEmulation
      >
        {this.initialType === 'identifier' && (
          <>
            {encodingValidation && encodingValidationResponse && (
              <Page.Sidebar width={500}>
                <EncodingProduct
                  product={EncodingExtensions.getEncodingProduct(encodingValidationResponse)}
                  clear={this.onClear}
                  fields={getEncodingProductFields()}
                />
              </Page.Sidebar>
            )}
            <Page.Content notBoxed>
              {(!encodingValidation || !encodingValidationResponse) && (
                <EncodingReading
                  starting={starting}
                  startAntenna={this.startAntenna}
                  antennaOn={antennaOn}
                  message={
                    antennaOn
                      ? __(T.messages.bring_an_item_to_antenna)
                      : __(T.messages.connection_to_station_in_progress)
                  }
                  identifier={
                    hasRfidAndSerial
                      ? {
                          identifierType: 'SIMPLE_ITEM_IDENTIFIER',
                          _status: 'TO_BE_READ',
                        }
                      : undefined
                  }
                  onInputSubmit={hasRfidAndSerial ? (barcode) => this.verifySerial({ barcode } as any) : undefined}
                />
              )}
              {encodingValidation && encodingValidationResponse && (
                <EncodingIdentifers
                  identifiers={identifiers}
                  clearReads={this.clear} //this.checkForceAssociation
                  onTagRead={this.onTagReadIdentifier}
                  encodingValidation={encodingValidation}
                  associationStatus={associationStatus}
                  disabledRetry={false}
                />
              )}
            </Page.Content>
          </>
        )}
        {this.initialType !== 'identifier' && (
          <>
            <Page.Sidebar width={500}>
              <EncodingForm
                product={EncodingExtensions.getEncodingProduct(encodingValidationResponse)}
                data={formSchemaData}
                operation={this.operation}
                onSubmit={this.onSubmitForm}
                updateFormSchemaData={this.updateFormSchemaData}
                onProductionOrderRowClear={this.onClear}
                onIdentifierClear={this.clearReads}
                counters={counters}
                resetStateCounter={resetFormCounter}
                disabledClear={disabledClear}
                disableInput={this.getDisableInput()}
              />
            </Page.Sidebar>
            <Page.Content notBoxed>
              {identifiers && encodingValidation && (
                <EncodingIdentifers
                  identifiers={identifiers}
                  clearReads={this.clearReads} //this.checkForceAssociation
                  onTagRead={this.onTagRead}
                  encodingValidation={encodingValidation}
                  associationStatus={associationStatus}
                  disabledRetry={this.getDisableRetry()}
                  fullWidthAssociationBox={this.getFullWidthAssociationBox()}
                />
              )}
              {!identifiers && (
                <Box borderRadius={15} ph={40} flex bg="white" center>
                  <Text center style={{ width: 500 }} fontSize={30}>
                    {__(T.messages.fill_all_fields_and_search_an_item_configuration)}
                  </Text>
                </Box>
              )}
            </Page.Content>
          </>
        )}
        <WorkstationEmulationInfoBox />
        {processing && <FullLoadingLayer message={this.getProcessingMessage()} />}

        {showPin && (
          <InputModal
            title={__(T.titles.pin_modal)}
            labelInput="Pin"
            onClose={() => this.managerPinModal()}
            onConfirm={this.forceWithPin}
          />
        )}

        {!AppStore.hasAnyCapabilities('Operation.Encoding.perform') && (
          <FeedbackModal
            title={__('error.unauthorized')}
            subtitle={__('messages.not_allowed_operation')}
            onClose={() => Router.navigate('/')}
            btnCloseModal={__('misc.back')}
          />
        )}
      </Page>
    )
  }
}
